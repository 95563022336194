/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
@keyframes gnavi_sub {
	0% {
		opacity: 0;
		transform: scale(0.9) translateY(-15px);
	}
	100% {
		transform: scale(1) translateY(0);
	}
}

@keyframes ease_out_back {
	0% {
		transform: scale(0.3);
	}
	60% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes _on_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes on_opacity {
	0% {
		opacity: 0;
		display: none;
	}
	100% {
		opacity: 1;
	}
}

@keyframes hero_area {
	0% {
		opacity: 0;
		display: none;
		transform: scale(0.8);
	}
	20% {
		transform: scale(1);
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes pop-upwards {
	0% {
		transform: matrix(0.97, 0, 0, 1, 0, 12);
		opacity: 0;
	}
	20% {
		transform: matrix(0.99, 0, 0, 1, 0, 2);
		opacity: .7;
	}
	40% {
		transform: matrix(1, 0, 0, 1, 0, -1);
		opacity: 1;
	}
	70% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
	100% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
}

@keyframes pop-downwards {
	0% {
		transform: matrix(0.97, 0, 0, 1, 0, -12);
		opacity: 0;
	}
	20% {
		transform: matrix(0.99, 0, 0, 1, 0, -2);
		opacity: .7;
	}
	40% {
		transform: matrix(1, 0, 0, 1, 0, 1);
		opacity: 1;
	}
	70% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
	100% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
}

@keyframes card {
	0% {
		transform: rotateX(-90deg) rotateY(-20deg) rotateZ(10deg) scale(0.5) translateY(200px);
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* Slider */
.slick-loading .slick-list {
	background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
	font-family: 'slick';
	src: url("./fonts/slick.eot");
	src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
	font-weight: normal;
	font-style: normal;
}

/* Arrows */
.iblog_slider_area .slick-prev,
.iblog_slider_area .slick-next {
	padding: 0;
	border: 0 none;
	outline: none;
	position: absolute;
	line-height: 0px;
	font-size: 0px;
	background: transparent;
	color: transparent;
	display: block;
	width: 24px;
	height: 32px;
	cursor: pointer;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
}

.iblog_slider_area .slick-prev::before,
.iblog_slider_area .slick-next::before {
	transition: transform .15s ease-out;
}

.iblog_slider_area .slick-prev:hover, .iblog_slider_area .slick-prev:focus,
.iblog_slider_area .slick-next:hover,
.iblog_slider_area .slick-next:focus {
	outline: none;
}

.iblog_slider_area .slick-prev:hover::before, .iblog_slider_area .slick-prev:focus::before,
.iblog_slider_area .slick-next:hover::before,
.iblog_slider_area .slick-next:focus::before {
	animation-name: arrow_move_right;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.iblog_slider_area .slick-prev.slick-disabled,
.iblog_slider_area .slick-next.slick-disabled {
	position: absolute;
	pointer-events: none;
}

.iblog_slider_area .slick-prev {
	transform: rotate(180deg) translateY(50%);
	left: 0;
}

@media only screen and (max-width: 767px) {
	.root .iblog_slider_area .slick-prev {
		left: -10px;
		right: auto;
	}
}

[dir='rtl'] .iblog_slider_area .slick-prev {
	left: auto;
	right: 0;
}

.iblog_slider_area .slick-prev.slick-disabled {
	left: auto;
}

.iblog_slider_area .slick-next {
	left: auto;
	right: 0;
}

@media only screen and (max-width: 767px) {
	.root .iblog_slider_area .slick-next {
		right: -10px;
		left: auto;
	}
}

[dir='rtl'] .iblog_slider_area .slick-next {
	right: 0;
	left: auto;
}

.iblog_slider_area .slick-next.slick-disabled {
	left: auto;
}

/* Dots */
.slick-dots {
	position: absolute;
	bottom: 6px;
	z-index: 11;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
}

@media only screen and (max-width: 767px) {
	.slick-dots {
		bottom: 5px;
	}
}

.slick-dots li {
	position: relative;
	display: inline-block;
	width: 11px;
	height: 11px;
	margin: 0 4px;
	padding: 0;
	cursor: pointer;
}

.slick-dots li button {
	background-image: url(/common/img/sprite-icon.png);
	background-position: 0px -26px;
	width: 8px;
	height: 8px;
	background-size: 50px 222px;
	border: 0;
	outline: none;
	line-height: 0px;
	color: transparent;
	font-size: 0px;
	padding: 0;
	display: block;
	width: 11px;
	height: 11px;
	cursor: pointer;
	box-sizing: content-box;
	background-color: transparent;
}

.slick-dots li button:hover, .slick-dots li button:active {
	outline: none;
	background-image: url(/common/img/sprite-icon.png);
	background-position: 0px -68px;
	width: 11px;
	height: 11px;
	background-size: 50px 222px;
}

.slick-dots li button:focus {
	outline: none;
}

.slick-dots li.slick-active button {
	background-image: url(/common/img/sprite-icon.png);
	background-position: 0px -68px;
	width: 11px;
	height: 11px;
	background-size: 50px 222px;
}
